import React from 'react'
import {Close, Logo, Menu} from "../../icons/icons";
import {Text18} from "../../styles/fonts";
import {useTheme} from "styled-components";
import {DefaultBtn} from "../../styles/button";
import {Navbar} from "./Navbar";
import {ScrollToEnum, TabEnum} from "../../enum";
import {useLocation, useNavigate} from "react-router-dom";
import {scrollTo} from "../../utils/scrollTo";

interface IHeaderProps {
    onPopupOpen(): void
    setIsMenuOpen(value: boolean): void
    isMenuOpen: boolean
}

export const Header = ({onPopupOpen, setIsMenuOpen, isMenuOpen}: IHeaderProps) => {
    const Theme = useTheme();
    const pathLoc= useLocation().pathname;
    const navigate=useNavigate();

    const navigateLink = (link:TabEnum, classN: ScrollToEnum)=>{
        navigate(link);
        setTimeout(() => scrollTo(classN), 1);
    }

    return (
        <div className={'header'}>
            <div className={'header-content animation2'}>
                <div className={'header-left'}>
                    <Logo onClick={() => navigateLink(TabEnum.about, ScrollToEnum.intro)}/>
                    <div className={'header-pc'}>
                            <Text18 color={(pathLoc === TabEnum.main || pathLoc === TabEnum.about )? Theme.colors.indicator : Theme.colors.secondary}
                                    hoverColor={Theme.colors.indicator}
                                    pointer={true}
                                    onClick={() => navigateLink(TabEnum.about, ScrollToEnum.intro)}
                            >
                                О продукте
                            </Text18>

                            <Text18
                                color={pathLoc === TabEnum.partners ? Theme.colors.indicator : Theme.colors.secondary}
                                hoverColor={Theme.colors.indicator}
                                pointer={true}
                                onClick={() => navigateLink(TabEnum.partners, ScrollToEnum.partners)}>
                                Партнерам
                            </Text18>

                            <Text18
                                color={pathLoc === TabEnum.support ? Theme.colors.indicator : Theme.colors.secondary}
                                hoverColor={Theme.colors.indicator}
                                pointer={true}
                                onClick={() => navigateLink(TabEnum.support, ScrollToEnum.support)}>
                                Поддержка
                            </Text18>
                    </div>
                </div>
                <div>
                    <div className={'header-pc'}>
                        <DefaultBtn onClick={onPopupOpen} style={{backgroundColor: Theme.colors.darkGrey}}>
                            <Text18 color={Theme.colors.secondary} style={{fontWeight: 500}}>
                                Связаться c нами
                            </Text18>
                        </DefaultBtn>
                    </div>
                    <div className={'header-mobile'}>
                        {!isMenuOpen && <Menu onClick={() => setIsMenuOpen(true)}/>}
                        {isMenuOpen && <Close onClick={() => setIsMenuOpen(false)}/>}
                    </div>
                </div>
            </div>
            {isMenuOpen && <Navbar selectedTab={pathLoc} onClose={() => setIsMenuOpen(false)}/>}
        </div>
    )
}
