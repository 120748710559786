import {Header} from "./features/header/Header";
import {Route, BrowserRouter, Routes, Outlet, Navigate} from 'react-router-dom';
import {ThemeProvider} from "styled-components";
import {Theme} from "./styles/Theme";
import {MainPage} from "./components/MainPage";
import React, { useState} from "react";
import {ScrollToEnum, TabEnum} from "./enum";
import {Partners} from "./components/partners/Partners";
import {Footer} from "./features/footer/Footer";
import {Close, CooperationModal, ModalClose} from "./components/cooperation/style";
import {Cooperation} from "./components/cooperation/Cooperation";
import {Support} from "./components/support/Support";
import {Documentation} from "./components/support/documentation/Documentation";

const App = () => {
    const [popupOpened, setPopupOpened] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return <BrowserRouter>
        <Routes>
            <Route path='/' element={
                    <div className={`app ${(popupOpened || isMenuOpen) ? 'appBlocked' : ''}`}>
                    <ThemeProvider theme={Theme}>
                        <Header
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={(value) => setIsMenuOpen(value)}
                            onPopupOpen={() => setPopupOpened(true)}
                        />
                        <div className={'top'}/>
                        <Outlet/>
                        <Footer/>
                        {popupOpened && <CooperationModal>
                            <ModalClose onClick={() => setPopupOpened(false)}>
                                <Close/>
                            </ModalClose>
                            <Cooperation/>
                        </CooperationModal>}
                    </ThemeProvider>
                </div>
            }>
                <Route index element={<MainPage/>}/>
                <Route path={TabEnum.about} element={<MainPage/>}/>
                <Route path={TabEnum.partners} element={<Partners onPopupOpen={() => {setPopupOpened(true)}}/>}/>
                <Route path={TabEnum.support} element={<Support/>}/>
                <Route path={TabEnum.docs} element={<Documentation filterView={true}/>}/>
                <Route path={TabEnum.examples} element={<Documentation filterView={false}/>}/>
                <Route path="*" element={<Navigate to={TabEnum.main} replace />} />
            </Route>
        </Routes>
    </BrowserRouter>
}

export default App;
