import styled from "styled-components";
import {color, fontWeight} from "styled-system";
import {WindowSizePXEnum} from "../interface/window";

interface TextProps {
    hoverColor?: string
    pointer?: boolean
}

export const Text12 = styled.div<TextProps>`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
`

export const Text12Gray = styled(Text12)`
    color: #000000;
    opacity: 0.5;
`

export const Text14Gray = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.8px;
  color: #000000;
  opacity: 0.5;
`
export const Text16Gray = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.8px;
  color: #000000;
  opacity: 0.5;
`

export const Text14 = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.8px;
`

export const Text18 = styled.div<TextProps>`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  ${color};
  ${fontWeight};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 16px;
    line-height: 26px;
  }
  cursor: ${(props) => props.pointer? 'pointer' : ''};

  :hover {
    color: ${(props) => props.hoverColor}
  }
`
export const Text18lh28 = styled(Text18)`
  line-height: 28px;
`
export const TextLink = styled.a`
   width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.indicator};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 16px;
    line-height: 26px;
  }
  cursor: pointer;
  :hover {
    filter: opacity(.8);
  }
  :active {
    filter: opacity(.6);
  }
`
export const TextLinkDiv = styled.div`
   width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.indicator};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 16px;
    line-height: 26px;
  }
  cursor: pointer;

  :hover {
    filter: opacity(.8);
  }
  :active {
    filter: opacity(.6);
  }
`

export const Link = styled(Text18lh28)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const Title40 = styled.div`
  text-align: center;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    font-size: 28px;
    line-height: 39.2px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 24px;
    line-height: 33.6px;
  }
`

export const Title48 = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    font-size: 40px;
    display: inline-block;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 24px;
    line-height: 34px;
  }
`

export const Title48lh67 = styled(Title48)`
  line-height: 67px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    line-height: 56px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    line-height: 34px;
  }
`
export const Title48lh58 = styled(Title48)`
  line-height: 58px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    line-height: 56px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    line-height: 34px;
  }
`

export const Title24 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 18px;
    line-height: 34px;
  }
`
export const Title24Bold = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 32px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Text16 = styled.div<TextProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  ${color};
`
export const Text16lh24px = styled(Text16)`
  cursor: ${(props) => props.pointer ? 'pointer' : ''};

  :hover {
    color: ${(props) => props.hoverColor}
  }

  line-height: 24px;
  ${color};
`

export const Text24 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 20px;
  }
`