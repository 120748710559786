import styled from "styled-components";
import {WindowSizePXEnum} from "../../interface/window";
import {color, fontWeight} from "styled-system";
import {DefaultBtn} from "../../styles/button";
import {Theme} from "../../styles/Theme";
import {Flex, FlexColumn} from "../../styles/content";

export const Title48 = styled.div`
  text-align: center;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    font-size: 40px;
    line-height: 48px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Text18XS14 = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  ${color};
  ${fontWeight};
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 14px;
    line-height: 17.5px;
  }
`

export const Text14XS14 = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  ${color};
  ${fontWeight};
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 14px;
    line-height: 17.5px;
  }
`

export const Text14XS14Description = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  ${color};
  ${fontWeight};
  margin: 5px 0 0 0;
  opacity: 0.5;
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 14px;
    line-height: 17.5px;
  }
`

export const TextAlignEndXS = styled(Text14XS14)`
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    margin-left: auto;
  }
`

export const Text18XS14Bold = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  ${color};
  ${fontWeight};
`
export const Title24Bold = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 32px;
  ${color};
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Title24BoldCursor = styled(Title24Bold)`
  cursor: pointer;
  width: fit-content;
  color: ${Theme.colors.indicator};
  font-weight: 500;
  margin-bottom: 6px;
`
export const FlexComponent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SupportHeaderContainer = styled.div`
  height: 512px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  margin:0 auto;
  background: ${({theme}) => theme.colors.lightGrey};
`

export const SupportHeaderContent = styled.div`
    width: 679px;
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 280px;
  }
`

export const SupportHeaderTitle = styled.div`
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: 679px;
    margin: auto;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    width: 569px;
    margin: auto;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 280px;
  }
`

export const SupportHeaderText = styled.div`
  margin: 24px 40px;
  text-align: center;
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    margin: 24px 0 20px;
  }
`

export const SupportHeaderFooter = styled.div`
  width: 296px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 100%;
  }
`

export const ButtonWithMaxXS = styled(DefaultBtn)`
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 100%;
  }
`

export const SupportContentContainer = styled.div`
  padding: 50px 100px;
  max-width: 1600px;
  margin:0 auto;
  
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 40px 32px;
  }
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 40px 16px;
  }
`

export const SupportInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: ${Theme.border.default};

  
`
export const InputElement = styled.input`
  width: 100%;
  border: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-size: 14px;
  }
`

export const Item = styled.div`
  display: flex;
  gap: 20px;
  background-color: ${Theme.colors.lightGrey};
  padding: 24px;
  align-items: center;
`

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 21px;
  border-radius: 12px;
  padding: 2px 10px;
  background-color: #1192E8;
  color: #FFFFFF;
`

export const DocumentButton = styled.a`
  color: ${Theme.colors.indicator};
  text-decoration: underline;
  cursor: pointer;
`

export const FlexGap12px = styled(FlexColumn)`
  gap: 12px
`

export const FlexContentEnd = styled(Flex)`
  justify-content: end;
  color: ${Theme.colors.indicator};
  padding: 12px 0;
  cursor:pointer;
`

export const ItemContainer = styled.div`
  background-color: ${Theme.colors.lightGrey};
  padding: 24px;
  cursor: pointer;
`

