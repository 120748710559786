import React, {useEffect, useRef, useState} from 'react'
import {IntroContainer, IntroContent, IntroLeftContent, IntroRightContent} from "./style";
import {Text18lh28, Title48} from "../../styles/fonts";
import {Theme} from "../../styles/Theme";
import {DefaultBtn} from "../../styles/button";
import {Content16, ContentTop16} from "../../styles/content";
// @ts-ignore
import MyVideo from './video.mp4'
import useOnScreen from "../../hooks/useOnScreen";
import {scrollTo} from "../../utils/scrollTo";
import {ScrollToEnum} from "../../enum";

export const Intro = () => {
    const ref = useRef(null)
    const videoRef = useRef(null)
    const visible = useOnScreen(ref)
    const videoVisible = useOnScreen(videoRef)
    const [isVisible, setIsVisible] = useState(false)
    const [isVideoVisible, setIsVideoVisible] = useState(false)

    useEffect(() => {
        setIsVisible(visible)
    }, [visible])

    useEffect(() => {
        if (videoVisible) {
            setIsVideoVisible(true)
        }
        const video = document.getElementById('IntroVideo') as HTMLVideoElement
        if (video) {
            if (videoVisible) {
                video.play()
            } else {
                video.currentTime = 0
            }
        }
    }, [videoVisible])


    return (
        <IntroContainer className={'intro'}>
            <IntroContent ref={ref}>
                <IntroLeftContent className={isVisible ? 'animation2' : ''}>
                    <Title48 color={Theme.colors.secondary}>Комплексный подход </Title48>
                    <Title48 color={Theme.colors.secondary}> к обеспечению</Title48>
                    <Title48 color={Theme.colors.secondary}> безопасности</Title48>
                    <Content16 className={isVisible ? 'animation2' : ''}>
                        <Text18lh28 color={Theme.colors.secondary}>
                            DECK AUTH обеспечивает надёжный контроль за сетевыми соединениями (Network Access Control) ваших сотрудников или гостей. Снижение рисков при организации проводных, беспроводных и удалённых подключений обеспечивается при помощи простых и гранулярных политик безопасности, а так же гибкости встраивания в существующую экосистему ИБ
                            и IT-инфраструктуру
                        </Text18lh28>
                    </Content16>
                    <ContentTop16>
                        <DefaultBtn onClick={() => scrollTo(ScrollToEnum.cooperation)}
                                    style={{backgroundColor: Theme.colors.indicator}}>
                            <Text18lh28 color={Theme.colors.secondary}>Получить демо</Text18lh28>
                        </DefaultBtn>
                    </ContentTop16>
                </IntroLeftContent>

                <IntroRightContent ref={videoRef} className={isVideoVisible ? 'animation5' : ''}>
                    <video id={'IntroVideo'} width="100%" height="100%" playsInline autoPlay muted loop>
                        <source src={MyVideo} type="video/mp4"/>
                        Your browser does not support HTML5 video.
                    </video>
                </IntroRightContent>
            </IntroContent>
        </IntroContainer>
    )
}
