import React from 'react'
import {NavbarBackground, NavbarBtn, NavbarItem} from "../../styles/content";
import {Theme} from "../../styles/Theme";
import {Text16lh24px, Text18lh28} from "../../styles/fonts";
import {DefaultBtn} from "../../styles/button";
import {scrollTo} from "../../utils/scrollTo";
import {ScrollToEnum, TabEnum} from "../../enum";
import {useLocation, useNavigate} from "react-router-dom";

interface NavbarProps {
    onClose: () => void
    selectedTab: string
}

export const Navbar = ({onClose, selectedTab}: NavbarProps) => {
    const pathLoc= useLocation().pathname;
    const navigate=useNavigate();

    const navigateLink = async(link:TabEnum, classN: ScrollToEnum)=>{
        await navigate(link);
        await scrollTo(classN)
    }

    return (
        <NavbarBackground>
            <NavbarItem
                onClick={() => {
                    onClose();
                    navigateLink(TabEnum.about,ScrollToEnum.intro);
                }}>
                <Text16lh24px color={(pathLoc === TabEnum.main || pathLoc === TabEnum.about) ? Theme.colors.indicator : Theme.colors.secondary} hoverColor={Theme.colors.indicator}>
                    О продукте
                </Text16lh24px>
            </NavbarItem>

            <NavbarItem
                onClick={() => {
                    onClose();
                    navigateLink(TabEnum.partners, ScrollToEnum.partners);
                }}>
                <Text16lh24px
                    color={pathLoc === TabEnum.partners ? Theme.colors.indicator : Theme.colors.secondary}
                    hoverColor={Theme.colors.indicator}>
                    Партнерам
                </Text16lh24px>
            </NavbarItem>
            <NavbarItem
                onClick={() => {
                    onClose();
                    navigateLink(TabEnum.support, ScrollToEnum.support);
                }}>
                <Text16lh24px
                    color={pathLoc === TabEnum.support ? Theme.colors.indicator : Theme.colors.secondary}
                    hoverColor={Theme.colors.indicator}>
                    Поддержка
                </Text16lh24px>
            </NavbarItem>
            <NavbarBtn>
                <DefaultBtn onClick={() => {
                    onClose()
                    navigateLink(TabEnum.about, ScrollToEnum.cooperation);
                }} style={{backgroundColor: Theme.colors.indicator}}>
                    <Text18lh28 color={Theme.colors.secondary}>Связаться c нами</Text18lh28></DefaultBtn>
            </NavbarBtn>
        </NavbarBackground>
    )
}
