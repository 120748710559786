import {DefaultTheme} from "styled-components";

enum Color {
    Primary = '#1B1B1B',
    Secondary = '#FFFFFF',
    Indicator = '#1192E8',
    DarkGrey = '#313131',
    LightGrey = '#F2F2F2',
    LinkGrey = '#484848',
    Black = '#000000',
}

enum Border {
    Default = `1px solid #E1E1E1`,
}

const Theme: DefaultTheme = {
    colors: {
        primary: Color.Primary,
        secondary: Color.Secondary,
        indicator: Color.Indicator,
        darkGrey: Color.DarkGrey,
        lightGrey: Color.LightGrey,
        linkGrey: Color.LinkGrey,
        black: Color.Black,
    },
    border: {
        default: Border.Default
    }
}

export {
    Theme,
    Color
}
