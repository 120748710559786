import * as React from 'react';
import {
    ButtonWithMaxXS,
    FlexContentEnd,
    InputElement,
    SupportContentContainer,
    SupportHeaderContainer,
    SupportHeaderContent,
    SupportHeaderFooter,
    SupportHeaderText,
    SupportHeaderTitle,
    SupportInputContainer,
    Text18XS14,
    Text18XS14Bold,
    Title48,
    Title24BoldCursor,
    FlexComponent
} from "./style";
import {Text12Gray, Text18lh28, Title40} from "../../styles/fonts";
import {Theme} from "../../styles/Theme";
import {ContentTop8, ContentTopStart40} from "../../styles/content";
import {Search} from "../../icons/icons";
import DocumentItem from "./DocumentItem";
import settings from '../../config/settings.json'
import documentation from '../../config/documentation.json'
import {useState} from "react";
import {ScrollToEnum, TabEnum} from "../../enum";
import {useNavigate} from "react-router-dom";
import {scrollTo} from "../../utils/scrollTo";

export interface Doc {
    id: number
    name: string
    category: string
    subcategory?: string
    version: string
    company?: string
    date: string
    url: string
}

export interface Doc2 {
    subcategory: string
    docs: Doc[]
}


export interface DocsItem {
    name: string
    docs: Doc[] | Doc2[]
    new?: boolean
    version?: string
}

export const Support = () => {
    const [inputSearch,setInputSearch]=useState('');
    const navigate=useNavigate();

    const navigateLink = (link:TabEnum, classN: ScrollToEnum)=>{
        navigate(link);
        setTimeout(() => scrollTo(classN), 1);
    }

    return (
        <div className={'support'}>
            <SupportHeaderContainer>
                <SupportHeaderContent>
                    <SupportHeaderTitle>
                        <Title48>
                            Портал службы технической поддержки
                        </Title48>
                    </SupportHeaderTitle>
                    <SupportHeaderText>
                        <Text18XS14>
                            В разделе представлена техническая документация, которая поможет в установке, настройке и эксплуатации DECK AUTH
                        </Text18XS14>
                    </SupportHeaderText>
                    <SupportHeaderFooter>
                        <ButtonWithMaxXS
                             style={{backgroundColor: Theme.colors.indicator}}
                        >
                            <Text18lh28
                                onClick={() => window.open('https://otrs.deck.lc/otrs/customer.pl', '_blank')}
                                color={Theme.colors.secondary}
                            >
                                Открыть заявку
                            </Text18lh28>
                        </ButtonWithMaxXS>
                        <Text12Gray style={{textAlign: 'center'}}>
                            Для открытия сервисной заявки требуется активный контракт на техническую поддержку
                        </Text12Gray>
                    </SupportHeaderFooter>
                </SupportHeaderContent>
            </SupportHeaderContainer>
            <SupportContentContainer>
                <Title40 style={{textAlign: 'left'}}>
                    Ресурсы для пользователей системы
                </Title40>
                <ContentTopStart40>
                    <Text18XS14Bold>
                        Поиск
                    </Text18XS14Bold>
                    <ContentTop8>
                        <SupportInputContainer>
                            <Search />
                            <InputElement
                                value={inputSearch}
                                onChange={(e)=>setInputSearch(e.target.value)}
                                placeholder={'Введите название документа'}
                                />
                        </SupportInputContainer>
                    </ContentTop8>
                </ContentTopStart40>
                <ContentTopStart40>
                    <FlexComponent>
                        <Title24BoldCursor onClick={()=>navigateLink(TabEnum.docs, ScrollToEnum.docAndExamples)}>
                            Документация
                        </Title24BoldCursor>
                        <FlexContentEnd onClick={()=>navigateLink(TabEnum.docs, ScrollToEnum.docAndExamples)}>
                            Все версии
                        </FlexContentEnd>
                    </FlexComponent>
                    {documentation.map((item, index) => (
                        <DocumentItem inputSearch={inputSearch} documentsItem={item.data} key={`${item.data.name}_${index}`} index={index}/>
                    ))}
                </ContentTopStart40>
                <ContentTopStart40>
                    <FlexComponent>
                        <Title24BoldCursor onClick={()=>navigateLink(TabEnum.examples, ScrollToEnum.docAndExamples)}>
                            Примеры настройки
                        </Title24BoldCursor>
                        <FlexContentEnd onClick={()=>navigateLink(TabEnum.examples, ScrollToEnum.docAndExamples)}>
                            Все примеры
                        </FlexContentEnd>
                    </FlexComponent>
                    {settings.map((item, index) => (
                        <DocumentItem inputSearch={inputSearch} documentsItem={item.data} key={`${item.data.name}_${index}`} index={index}/>
                    ))}
                </ContentTopStart40>
            </SupportContentContainer>
        </div>
    );
};
