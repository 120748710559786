import styled from "styled-components";
import {WindowSizePXEnum} from "../../../interface/window";
import {color, fontWeight} from "styled-system";
import {Theme} from "../../../styles/Theme";
import {Flex, FlexRow} from "../../../styles/content";
import {Text14Gray, Text16, Text16Gray} from "../../../styles/fonts";

export const Text18XS14 = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  ${color};
  ${fontWeight};
  
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    font-size: 14px;
    line-height: 17.5px;
  }
`
export const Text18XS14Bold = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  ${color};
  ${fontWeight};
`
export const DocumentationContentContainer = styled.div`
    padding: 80px 100px;
  max-width: 1600px;
  margin:0 auto;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 40px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 40px 16px;
  }
`
export const DocumentationLink = styled(Flex)`
  margin-top: 20px;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    margin-top: 0;
  }
`

export const DocumentationInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: ${Theme.border.default};
`
export const InputElement = styled.input`
  width: 100%;
  border: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-size: 14px;
  }
`


export const FilterContainer = styled.div`
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    flex-direction: column;
    position:relative;
  }
`
export const FilterContainerLeft = styled.div`
  padding: 20px 24px;
  width: 310px;
  min-height: 724px;
  height: auto;
  border-radius: 4px;
  background-color: rgba(240, 240, 240);
  box-shadow: 0 2px 4px 0 rgba(105, 105, 105, 0.25), 0 4px 10px 0 rgba(105, 105, 105, 0.15);
  z-index: 1;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    top:50px;
    width: auto;
    height: auto;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    min-height: auto;
    padding: 10px;
    position:absolute;
    width: 100%;
    height: auto;
  }
`

export const FilterClick = styled.div`
  padding: 12px 10px;
  width: auto;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(240, 240, 240);
  box-shadow: 0 2px 4px 0 rgba(105, 105, 105, 0.25), 0 4px 10px 0 rgba(105, 105, 105, 0.15);
  cursor: pointer;
  display:none;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    display:flex;
  }
`
export const FilterClickHeader = styled.div`
  margin:auto 8px;
  font-size: 14px;
`
export const FilterCountCheckbox = styled.div`
  background-color:rgba(17, 146, 232, 1);
  min-width: 18px;
  min-height: 18px;
  border-radius: 100%;
  padding:1px 5px;
  font-size: 13px;
  color:#ffffff;
`
export const FilterContainerRight = styled.div`
  padding: 0 40px 20px;
  flex: 1 1 auto;
  min-height: 724px;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 10px;
  }
`
export const LeftHeader = styled(Text16)`
  padding: 8px 15px;
  margin-top: 5px;
  max-width: 272px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.002em;
`
export const LeftCheckbox = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 2px 12px;
  width: auto;
`
export const CheckboxText = styled(Text16)`
  margin: auto 0 auto 12px;
`
export const RightHeader = styled.div`
  font-weight: 400;
  font-size: 24px;
  margin-top: 30px;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    margin-top: 20px;
  }
`
export const RightDescr = styled(Text14Gray)`
  
`
export const RightTitleList = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
`
export const TitleListCompany = styled(Text16Gray)`
  font-weight: 400;
`
export const RightTitleListElement = styled(FlexRow)`
  justify-content: space-between;
  margin-top: 15px;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    flex-direction: column;
  }
`

