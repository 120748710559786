export enum TabEnum {
    main = '/',
    about = '/about',
    partners = '/partners',
    support = '/support',
    docs = '/docs',
    examples = '/examples',
}

export enum ScrollToEnum {
    top = '.top',
    intro = '.intro',
    partners = '.partners',
    cooperation = '.cooperation',
    support = '.support',
    docAndExamples = '.docAndExamples',
}

export enum Category {
    Documentation = 'Документация',
    SettingExamples = 'Примеры настройки'
}

export enum Subcategory {
    Design = 'Дизайн и проектирование',
    Download = 'Установка',
    Administration = "Администрирование",
    Malfunction = 'Поиск и устранение неисправностей'
}

export enum Company {
    Aruba = 'Aruba',
    Microsoft = 'Microsoft',
    Cisco = 'Cisco',
    Eltex = 'Eltex',
    Huawei = 'Huawei',
    VectorTechnologies = 'Вектор Технологии',
    Any = 'Any'
}

