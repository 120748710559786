import {
    Badge,
    DocumentButton,
    FlexGap12px,
    Item,
    ItemContainer,
    Text14XS14,
    Text14XS14Description,
    TextAlignEndXS
} from "./style";
import {ArrowDown, ArrowRight} from "../../icons/icons";
import {Text12, Text24} from "../../styles/fonts";
import {ContentTop12, ContentTop24, FlexRowSpaceBetween} from "../../styles/content";
import * as React from "react";
import {useEffect, useState} from "react";
import {DocsItem} from "./Support";
import {Theme} from "../../styles/Theme";

interface DocumentItemProps {
    documentsItem: DocsItem
    inputSearch:string
    index: number
}


const DocumentItem = ({documentsItem, inputSearch, index}:DocumentItemProps ) => {
    const [open, setOpen] = useState(false)

    const isDocumentation = documentsItem.docs.some(i => i.subcategory)



    useEffect(() => {
        if(inputSearch!==''){
            let count=0;
            documentsItem.docs.forEach((doc:any) => {
                if (isDocumentation) {
                    doc.docs.forEach((item: any) => {
                        if(item.name.toLowerCase().includes(inputSearch.toLowerCase())){
                            count++
                        }
                    })
                } else {
                    if(doc?.name.toLowerCase().includes(inputSearch.toLowerCase())){
                        count++
                    }
                }
            })
            count>0 ? setOpen(true):setOpen(false)
        }else{
            setOpen(false)
        }
    }, [inputSearch, isDocumentation, documentsItem.docs]);

    useEffect(() => {
        if (!isDocumentation) {
            setOpen(true)
        }
        if (index === 0) {
            setOpen(true)
        }
    }, [isDocumentation, index]);


    return (
        <ContentTop12>
            <ItemContainer onClick={() => setOpen(!open)}>
                <Item style={{padding: 0}}>
                    {open ? <ArrowDown/> : <ArrowRight/>}
                    <Text24>
                        {documentsItem.name}
                        {' '}
                        {documentsItem.version && <span>
                            {documentsItem.version}
                        </span>}
                    </Text24>

                    <Badge>
                        <Text12 style={{paddingTop: '3px'}}>
                            New
                        </Text12>
                    </Badge>
                </Item>
                {open && <ContentTop24>
                    <FlexGap12px>
                        {isDocumentation ?
                            documentsItem.docs.map((doc:any) => (
                                doc.docs.map((item:any)=>(
                                    <FlexRowSpaceBetween key={item.id}>
                                        <div>
                                            <DocumentButton
                                                rel={'noreferrer'}
                                                target={"_blank"}
                                                href={item.url}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {item.name}
                                            </DocumentButton>
                                            {item.description && <Text14XS14Description color={Theme.colors.black}>
                                                {item.description}
                                            </Text14XS14Description>}
                                        </div>
                                        <TextAlignEndXS style={{opacity: '0.5'}}>
                                            {item.date}
                                        </TextAlignEndXS>
                                    </FlexRowSpaceBetween>
                                ))

                            )) :
                            documentsItem.docs.map((doc:any) => (
                                <FlexRowSpaceBetween key={doc.id}>
                                    <DocumentButton
                                        rel={'noreferrer'}
                                        target={"_blank"}
                                        href={doc.url}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {doc.name}
                                    </DocumentButton>
                                    <TextAlignEndXS style={{opacity: '0.5'}}>
                                        {doc.date}
                                    </TextAlignEndXS>
                                </FlexRowSpaceBetween>
                            ))
                        }
                    </FlexGap12px>
                </ContentTop24>}
            </ItemContainer>
        </ContentTop12>
    )
}

export default DocumentItem
